import React from "react";
import "./Promo.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import imgPromo from "../../media/promo/newpromo/design hardsel 34 web (juli-present)) (4).png";

const Promo = () => {
  const handleConsultationClick = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send?phone=62895417144022&text=Halo%20Sandy,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Promo)%20(https://grandwisataofficial.id/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0";
    window.location.href = whatsappLink;
  };
  return (
    <div>
      <div className="containerPenawaran">
        <div id="promo" className="containergambar">
          <div className="carousellogo">
            <img src={imgPromo} alt="" />
          </div>
        </div>
        <div className="containercontent">
          <div className="penawarantitle2">PROMO TERBARU :</div>
          <div className="pointpenawaran">
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span style={{ color: "black" }}>&nbsp;&nbsp;Free DP</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span style={{ color: "black" }}>&nbsp;&nbsp;Free PPN</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span style={{ color: "black" }}>
                &nbsp;&nbsp;Disc Special KPR DP cicil 2%*
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span style={{ color: "black" }}>
                &nbsp;&nbsp;Disc Special Cash Bertahap 5*
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span style={{ color: "black" }}>
                &nbsp;&nbsp;Disc Special Cash Keras 10%*
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span style={{ color: "black" }}>
                &nbsp;&nbsp;Disc Early Birth 10 Juta
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span style={{ color: "black" }}>
                &nbsp;&nbsp;Disc Lucky Dip 10 Juta
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span style={{ color: "black" }}>
                &nbsp;&nbsp;Free Smardoor Lock
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span style={{ color: "black" }}>&nbsp;&nbsp;Free Canopy</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span style={{ color: "black" }}>
                &nbsp;&nbsp;Free 2 Unit CCTV
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span style={{ color: "black" }}>
                &nbsp;&nbsp;Free Solar Panel
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span style={{ color: "black" }}>&nbsp;&nbsp;Free Lift</span>
            </div>
          </div>
          <div className="disclaimer">*S&K Berlaku</div>
          <button className="buttonpenawaran" onClick={handleConsultationClick}>
            Request Consultation
          </button>
        </div>
      </div>
    </div>
  );
};

export default Promo;
