import React from "react";
import "./Launching.scss";
import LT6 from "../../media/LT6/index.js";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faBed,
  faShower,
  faMaximize,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import LT7 from "../../media/LT7/index.js";
import LT8 from "../../media/LT8/index.js";
import LT10 from "../../media/LT10/index.js";
import Kaia from "./new-kaia/kaia.jsx";
import Vicente from "./new-vicente/vicente.jsx";

const Launching = () => {
  const levante6 = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send?phone=62895417144022&text=Halo%20Sandy,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Levante6)%20(https://grandwisataofficial.id/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0";
    window.location.href = whatsappLink;
  };
  const levante7 = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send?phone=62895417144022&text=Halo%20Sandy,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Levante7)%20(https://grandwisataofficial.id/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0";
    window.location.href = whatsappLink;
  };
  const levante8 = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send?phone=62895417144022&text=Halo%20Sandy,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Levante8)%20(https://grandwisataofficial.id/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0";
    window.location.href = whatsappLink;
  };
  const levante10 = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send?phone=62895417144022&text=Halo%20Sandy,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Levante10)%20(https://grandwisataofficial.id/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0";
    window.location.href = whatsappLink;
  };

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="launching-container">
      <div id="newlaunch" className="launching-title">
        NEW LAUNCHING
      </div>
      <div className="launching-subtitle">
        Vicente
        <br /> at Klasika Grand Wisata
      </div>
      <Vicente />
      <div className="launching-subtitle">
        Yara At The KAIA <br /> Heart of Grand Wisata
      </div>
      <Kaia />
      <div id="levante" className="launching-subtitle">
        LEVANTE <br />
        at Klasika Grand Wisata
      </div>
      <div className="launching-card">
        <div className="card">
          <Slider {...settings}>
            {LT6.map((lt6, index) => (
              <img
                className="card-image"
                key={index}
                src={lt6}
                alt={`Launching ${index + 1}`}
              ></img>
            ))}
          </Slider>
          <div className="content-card">
            <div className="card-title">LEVANTE T6</div>
            <div className="card-price">
              <div className="judul-pt">START FROM</div>
              <div className="pricetotal">6</div>
              <div className="ket-pt">JUTA/BULAN</div>
            </div>
            <div className="card-dp">TANPA DP</div>
            <div className="card-icon">
              <FontAwesomeIcon icon={faMaximize} />
              <span>: 72m² </span>
              <FontAwesomeIcon icon={faHouse} />
              <span>: 77m² </span>
              <FontAwesomeIcon icon={faBed} />
              <span>: 3 </span>
              <FontAwesomeIcon icon={faShower} />
              <span>: 2 </span>
            </div>
            <div className="card-button">
              <button className="button" onClick={levante6}>
                <FontAwesomeIcon size="lg" icon={faWhatsapp} />
                &nbsp;Whatsapp
              </button>
            </div>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {LT7.map((lt7, index) => (
              <img
                className="card-image"
                key={index}
                src={lt7}
                alt={`Launching ${index + 1}`}
              ></img>
            ))}
          </Slider>
          <div className="content-card">
            <div className="card-title">LEVANTE T7</div>
            <div className="card-price">
              <div className="judul-pt">START FROM </div>
              <div className="pricetotal">8</div>
              <div className="ket-pt"> JUTA/BULAN</div>
            </div>
            <div className="card-dp">TANPA DP</div>
            <div className="card-icon">
              <FontAwesomeIcon icon={faMaximize} />
              <span>: 91m² </span>
              <FontAwesomeIcon icon={faHouse} />
              <span>: 102m² </span>
              <FontAwesomeIcon icon={faBed} />
              <span>: 3+1 </span>
              <FontAwesomeIcon icon={faShower} />
              <span>: 2+1 </span>
            </div>
            <div className="card-button">
              <button onClick={levante7} className="button">
                {" "}
                <FontAwesomeIcon size="lg" icon={faWhatsapp} />
                &nbsp;Whatsapp
              </button>
            </div>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {LT8.map((lt7, index) => (
              <img
                className="card-image"
                key={index}
                src={lt7}
                alt={`Launching ${index + 1}`}
              ></img>
            ))}
          </Slider>
          <div className="content-card">
            <div className="card-title">LEVANTE T8</div>
            <div className="card-price">
              <div className="judul-pt">START FROM </div>
              <div className="pricetotal">10</div>
              <div className="ket-pt"> JUTA/BULAN</div>
            </div>
            <div className="card-dp">TANPA DP</div>
            <div className="card-icon">
              <FontAwesomeIcon icon={faMaximize} />
              <span>: 120m² </span>
              <FontAwesomeIcon icon={faHouse} />
              <span>: 120m² </span>
              <FontAwesomeIcon icon={faBed} />
              <span>: 3+1 </span>
              <FontAwesomeIcon icon={faShower} />
              <span>: 3+1 </span>
            </div>
            <div className="card-button">
              <button onClick={levante8} className="button">
                {" "}
                <FontAwesomeIcon size="lg" icon={faWhatsapp} />
                &nbsp;Whatsapp
              </button>
            </div>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {LT10.map((lt7, index) => (
              <img
                className="card-image"
                key={index}
                src={lt7}
                alt={`Launching ${index + 1}`}
              ></img>
            ))}
          </Slider>
          <div className="content-card">
            <div className="card-title">LEVANTE T10</div>
            <div className="card-price">
              <div className="judul-pt">START FROM </div>
              <div className="pricetotal">13</div>
              <div className="ket-pt"> JUTA/BULAN</div>
            </div>
            <div className="card-dp">TANPA DP</div>
            <div className="card-icon">
              <FontAwesomeIcon icon={faMaximize} />
              <span>: 140m² </span>
              <FontAwesomeIcon icon={faHouse} />
              <span>: 135m² </span>
              <FontAwesomeIcon icon={faBed} />
              <span>: 4+1 </span>
              <FontAwesomeIcon icon={faShower} />
              <span>: 2+1 </span>
            </div>
            <div className="card-button">
              <button onClick={levante10} className="button">
                {" "}
                <FontAwesomeIcon size="lg" icon={faWhatsapp} />
                &nbsp;Whatsapp
              </button>
            </div>
          </div>
        </div>
      </div>
      <hr className="garis" />
    </div>
  );
};

export default Launching;
